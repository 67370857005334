.designer-tabs{
  min-height: 90vh;

  .nav-tabs{
    border-bottom: none !important;
  }
  .nav-link.active{
    border: none !important;
  }

  .nav-link{
    border: none!important;
    text-align: center;
    label{
      font-size: 0.8rem;
    }
  }

  .text-primary{
    color: $primary!important;
  }

  .nav-tabs .nav-link:focus{
    border: none!important;
  }

  a {
    color: $font-color!important;
  }

  img:not(.logo-designer-icons img) {
    border: 1px solid $gray-200;
    padding: 8px;
    //padding: 16px;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }

  img:not(.logo-designer-icons img):hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}

.designer-component{
  border: 1px solid $gray-700;
}

.template-list-img {
  border: 1px solid $gray-200;
  padding: 0 !important;
  border-radius: 2px;
  box-shadow: none !important;
  width: 9.5rem;
  height: 9.5rem;
  @media screen and (max-width: 767px) {
    width: 45%;
    height: 8.5rem;
  }
}

.designer-component-img{
  border: 1px solid $gray-200;
  padding: 0 !important;
  border-radius: 2px;
  box-shadow: none !important;
  width: 4rem;
  height: 4rem;

  &.eye-pattern{
    width: 3.5rem;
    height: 3.5rem;
  }

  &.data-pattern{
    width: 5rem;
    height: 5rem;
  }

  &:hover {
    border: 1px solid $border-color !important;
  }
}

// For removing the down-arrow default icon of ngb-drop-down
.template-action,.text-logo-styles{
  .dropdown-toggle::after{
    display: none !important;
  }
}

@mixin radial-gradient($from, $to) {
}

@mixin linear-gradient($from, $to){
  background: $from; /* Old browsers */
  background: -moz-linear-gradient(top,  $from 0%, $to 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $from 0%,$to 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $from 0%,$to 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $from 0%,$to 100%); /* IE10+ */
  background: linear-gradient(to bottom,  $from 0%,$to 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

.data-pattern-color {

  .solid-color-group, .gradient-color-group {
    display: inline-block;
  }

  .mobile-gradient {
    .gradient {
      height: 3rem !important;
      width: 3rem !important;
    }
  }

  .gradient {
    height: 3rem;
    width: 3rem;
    border: 1px solid #bbb;
    margin: 0 0.9rem;
    display: inline-block;

    &.selected {
      margin-top: -1px;
      border: 3px solid $primary;
    }

    &.gradient-none {
      background: #DBDBDB;
    }
    &.gradient-h {
      background: -webkit-linear-gradient(to right, #DBDBDB, #595959);
      background: -moz-linear-gradient(to right, #DBDBDB, #595959);
      background: -ms-linear-gradient(to right, #DBDBDB, #595959);
      background: -o-linear-gradient(to right, #DBDBDB, #595959);
      background: linear-gradient(to right, #DBDBDB, #595959);
    }
    &.gradient-v {
      background: -webkit-linear-gradient(to bottom, #DBDBDB, #595959);
      background: -moz-linear-gradient(to bottom, #DBDBDB, #595959);
      background: -ms-linear-gradient(to bottom, #DBDBDB, #595959);
      background: -o-linear-gradient(to bottom, #DBDBDB, #595959);
      background: linear-gradient(to bottom, #DBDBDB, #595959);
    }
    &.gradient-radial {
      background: -moz-radial-gradient(circle at center, #DBDBDB 0%, #595959 100%);
      background: -webkit-gradient(radial, top right, bottom left, color-stop(0%, #DBDBDB), color-stop(100%, #595959));
      background: -webkit-radial-gradient(circle at center, #DBDBDB 0%, #595959 100%);
      background: -o-radial-gradient(circle at center, #DBDBDB 0%, #595959 100%);
      background: -ms-radial-gradient(circle at center, #DBDBDB 0%, #595959 100%);
      background: radial-gradient(circle at center, #DBDBDB 0%, #595959 100%);
      background-color: #595959;
    }
    &.gradient-d {
      background: -webkit-linear-gradient(to left top, #595959 0%, #ffffff 100%);
      background: -moz-linear-gradient(to left top, #595959 0%, #ffffff 100%);
      background: -ms-linear-gradient(to left top, #595959 0%, #ffffff 100%);
      background: -o-linear-gradient(to left top, #595959 0%, #ffffff 100%);
      background: linear-gradient(to left top, #595959 0%, #ffffff 100%);
    }
  }
}

/*
 QR Code Designer
*/
.designer-dropdown{
  .dropdown-toggle::after{
    content: unset !important;
  }
}

.logo-designer-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83px;
  height: 83px;
  border: 1px solid #D8D8D8;

  &:hover {
    border: 1px solid $border-color;
    background-color: $lighter;
  }

  &.active {
    border: 1px solid $secondary;
  }
}

/**
 Used in Logo Designer > Width is set wrt the QR CODE Image.
 */
.template-container {
  width: min-content;
}

.truncate-str{
  width: 7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.qr-display {
  .canvas-container {
    height: 185px !important;
    width: 185px !important;
    margin: unset !important;
  }

  .lower-canvas{
    height: 185px !important;
    width: 185px !important;
  }

  .upper-canvas {
    height: 185px !important;
    width: 185px !important;
  }
}

.qr-container-cursor-pointer {
  .upper-canvas {
    cursor: pointer !important;
  }
}

.qr-preview-container {

  .qr-loading-spinner {
    background: url('/assets/images/qr/demo_qrcode.png') no-repeat;
    background-size: contain;
  }

  .canvas-container {
    display: block;
  }

  &.sloading {
    .canvas-container {
      display: none;
    }
  }
}

.canvas-container {
  margin: auto !important;
}

//.border-neutral-light:hover {
//  @extend .border-dark
//}

.hover-border {
  @extend .border-1;
  @extend .border-white;
}
.hover-border:hover {
  @extend .border-dark;
}

.hover-border-primary:hover {
  @extend .border-primary;
  @extend .box-shadow;
}

.message-bubble {
  @media screen and (max-width: 992px) {
    background-color: $light;
    padding: 8px;
    border-radius: 8px;
    min-height: 110px;
    display: flex;
    align-items: center;
    position: relative;

    &:before {
      content: '';
      background-color: $light;
      height: 20px;
      width: 20px;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%) rotate(55deg) skew(20deg);
    }
  }
}

.frames-component {
  .img-container {
    border-radius: $border-radius;
    img {
      border-radius: $border-radius !important;
      &.active {
        border: 1px solid $secondary !important;
      }
      &:hover {
        border: 1px solid $border-color;
      }
    }
  }
}
