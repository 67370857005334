/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
//@import 'material-symbols/rounded.css';
//@import "argon-dashboard";
@import "material-dashboard";

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

/**
 *import flaticons as discussed material taking too mich time to render
 */
@import "~@flaticon/flaticon-uicons/css/regular/rounded";
@import "~@flaticon/flaticon-uicons/css/regular/straight";

//@import "../../../node_modules/hover.css/css/hover-min.css";

/**
 * Import ngx-toastr SCSS stylesheet
 */
//@import "~ngx-toastr/toastr-bs4-alert";
@import 'ngx-toastr/toastr';

/*
Import angular2 Multiselect drop down
*/
@import "angular2-multiselect-dropdown/themes/default.theme.css";
@import '@swimlane/ngx-datatable/index.css';
@import '@swimlane/ngx-datatable/themes/material.css';
@import '@swimlane/ngx-datatable/assets/icons.css';

/* Add styles & imports to this Script Editor BLock! */
@import 'codemirror/lib/codemirror.css';
@import 'codemirror/addon/fold/foldgutter.css';

/* Uppy Cloud Upload */
@import '@uppy/core/dist/style.min.css';
@import '@uppy/dashboard/dist/style.min.css';
@import '@uppy/image-editor/dist/style.min.css';
@import '@uppy/url/dist/style.min.css';
@import "social-icons/social-icons";

//@font-face {
//  font-family: Montserrat;
//  src: url(../fonts/Montserrat/montserrat-medium-webfont.ttf) format("opentype");
//  font-display: swap;
//}

*:not(font) {
  font-family: 'Montserrat', sans-serif;
}

html {
  font-size: 14px;
  font-weight: normal;
}

/**
 * Import application's global SCSS file
 * Import package specific files
 */
//@import "global";

.visible-md-and-lg {
  @extend .d-md-block, .d-sm-none, .d-none;
}

.visible-sm-and-xs{
    @extend .d-block, .d-sm-block, .d-md-none ;
}

@include  media-breakpoint-between(xs,sm) {

  .card-container{
    padding-top: 0;
  }

}

/*
To disable the field/div
 */
.disable-field{
  pointer-events: none;
  opacity: 0.6;
}
.not-allowed{
  cursor: not-allowed;
}

/**
color for QR Batch and QR API text
 */
.text-batch, text-batch:hover  {
  color: #6766E7 !important;
}

.text-api , text-api:hover{
  color: #FC8829 !important;
}
.required:after {
  content:"*";
  color: #C0392B;
  font-weight: bold;
}

.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group{
  background-color: unset !important;
}

/*
  Below styling is needed for Shepherd.js
  as it injects element at app-root level.
  (Work in progress...)
*/
.custom-shepherd-theme {
  .steps .shepherd-enabled.shepherd-element {
    transform: translateX(10%) !important;
  }

  .shepherd-header {
    background-color: $white !important;
    padding: 10px !important;
    padding-top: 12px !important;
    padding-bottom: 0 !important;
  }

  .shepherd-text {
    padding-top: 0 !important;
  }

  .shepherd-footer {
    padding: 20px 40px 16px;
    justify-content: space-between;
  }

  .shepherd-cancel-icon {
    span {
      color: $text-muted !important;
    }
    span:hover {
      color: $dark !important;
    }
  }

  .custom-secondary-btn {
    @extend .btn;
    border: 1px solid $primary !important;
    background-color: $white !important;
    color: $primary !important;
  }

  .custom-primary-btn {
    @extend .btn;
    @extend .btn-primary;
    @extend .text-white;
    &:hover {
      @extend .bg-primary;
    }
  }

  .shepherd-arrow::before {
    background-color: $white !important;
    border-top: 1px solid $white !important;
    border-right: 1px solid $white !important;
  }
}

@media screen and (min-width: 767px) {
  div.custom-shepherd-theme.steps {
    transform: translateX(3%) !important;
  }
}

/* Explicitly setting color for restricting iOS fallback font color */
ngx-daterangepicker-material div.ranges > ul > li > button,
app-button-slider :not(.slide-right),
.dropdown-toggle > span > span, .dropdown-toggle::after,
app-number-stepper > button,
ngx-daterangepicker-material div.calendar select {
  color: $dark;
}

.fi::before{
  vertical-align: middle;
  //font-size: 1.45em;
}

.continue-section-layout-mobile {
  height: 66px;
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.80);
  bottom: 0;
  left: 0;
}

.available-component-mob-position {
  top: 117px;
  z-index: 2;
}

/* Prevent scrolling while dragging */
.cdk-drag-preview, .cdk-drag-placeholder, .cdk-drag {
  touch-action: none;
}

.enter-content-side-img {
  z-index: 9 !important;
}
