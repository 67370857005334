.btn {
  //margin-bottom: $btn-margin-bottom;
  letter-spacing: $btn-letter-spacing;
  //text-transform: uppercase;
  background-size: $btn-background-size;
  background-position-x: $btn-background-position-x;
  position: relative;
  overflow: hidden;
  -webkit-appearance: none;

  &:not([class*="btn-outline-"]) {
    border: 0;
  }

  //&:active,
  //&:active:focus,
  //&:active:hover {
  //  box-shadow: $btn-hover-box-shadow;
  //  transform: $btn-active-hover-transform;
  //  opacity: $btn-hover-opacity;
  //}

  &.bg-white {
    @include hover {
      color: $body-color;
    }
  }

  &.btn-link{
    box-shadow: none;
    font-weight: $btn-font-weight;

    &:hover,
    &:focus{
      box-shadow: none;
    }
  }
  &.btn-round {
    border-radius: $btn-border-rounded;
  }
  // Button Just Icon

  &.btn-icon-only {
    //width: $btn-just-icon-width;
    //height: $btn-just-icon-height;
    //border: $btn-just-icon-border-width solid transparent;
    //color: inherit;
    padding: $btn-just-icon-padding-y $btn-just-icon-padding-x;
  }

  // Button Icon Sizes

  &.btn-sm {
    &.btn-icon-only {
      width: $btn-just-icon-width-sm;
      height: $btn-just-icon-height-sm;
      padding: $btn-just-icon-padding-y-sm $btn-just-icon-padding-x-sm;
    }
  }

  &.btn-md {
    height: $btn-md-height;
  }

  &.btn-lg {
    &.btn-icon-only {
      width: $btn-just-icon-width-lg;
      height: $btn-just-icon-height-lg;
      padding: $btn-just-icon-padding-y-lg $btn-just-icon-padding-x-lg;
    }
  }

  &.btn-rounded {
    border-radius: $btn-border-rounded;
  }

  //.material-symbols-outlined {
  //  vertical-align: middle;
  //  margin-top: $btn-material-icon-margin;
  //  margin-bottom: $btn-material-icon-margin;
  //  font-size: $btn-material-icon-font-size;
  //  display: $btn-material-icon-display;
  //  top: $btn-material-icon-top;
  //}
}

.btn-check{
  &:checked{
    +.btn{
      svg{
        .color-background{
          fill: $white;
        }
      }
      &:hover{
        svg{
          .color-background{
            fill: $dark;
          }
        }
      }
    }
  }
}

.icon-move-right {
  i {
    transition: $btn-icon-transition;
  }
  &:hover,
  &:focus {
    i {
      transform: $btn-icon-transform-right;
    }
  }
}

.icon-move-left{
  i {
    transition: $btn-icon-transition;
  }
  &:hover,
  &:focus {
    i {
      transform: $btn-icon-transform-left;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color},
  .btn.bg-gradient-#{$color} {
    @include colored-shadows($value);
    box-shadow: none;

    @include hover {
      background-color: $value;
      border-color: $value;

      //@include colored-shadows-hover($value);
    }

    .btn.bg-outline-#{$color} {
      border: $border-width solid $value;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      color: color-yiq($value);
      background-color: $value;
    }

    @if $value != $white and $value != $light {
      &.focus,
      &:focus {
        color: $white;
      }
    }

    @if $color == 'primary' {
      font-weight: $font-weight-bolder;
      &:hover {
        background-color: #F4503F !important;
      }
      &:active {
        background-color: $value !important;
      }
    }
  }

  @if $color != 'secondary' and $color != 'primary' and $color != 'light' {
    .btn-outline-#{$color} {
      box-shadow: none;
      @include hover {
        &:not(.active) {
          background-color: transparent;
          //opacity: .75;
          box-shadow: none;
          color: $value;
        }
      }
    }
  }

  // Add custom hover styling for btn-outline-primary
  @if $color == 'primary' {
    .btn-outline-#{$color} {
      font-weight: $font-weight-bolder;
      &:hover {
        background-color: $primary;
        box-shadow: none;
        color: $white;

        fi {
          color: $white;
        }
      }

      &:active {
        color: $value !important;
        background-color: transparent !important;
      }
    }
  }

  // Add custom hover styling for btn-outline-secondary
  @if $color == 'secondary' {
    .btn-outline-#{$color} {
      background-color: white;
      font-weight: $font-weight-normal;
      &:hover {
        background-color: $light;
        box-shadow: none;
        color: $value;
      }

      &:active {
        color: $value !important;
        background-color: transparent !important;
      }
    }
  }

  // Add custom hover styling for btn-outline-secondary
  @if $color == 'light' {
    .btn-outline-#{$color} {
      &:hover {
        background-color: $light;
        box-shadow: none;
        color: $primary;

        fi {
          color: $primary;
        }
      }

      &:active {
        color: $value !important;
        background-color: transparent !important;
      }
    }
  }

  .btn-outline-#{$color} {
    &.btn-hover {
      border: $btn-just-icon-border-width solid transparent;
      background-color: transparent !important;

      &:hover {
        color: $value;
        border: $btn-just-icon-border-width solid $value !important;
      }
    }
  }
}

.btn-outline-white{
  border-color: rgba($white, .75);
  background: rgba($white, .1);
}

@each $color, $value in $btn-font-colors {
  .btn-#{$color},
  .btn.bg-gradient-#{$color} {
    color: $value;
    @if $color != 'warning' {
      @include hover {
        color: $value !important;
      }
    }
  }
}

.btn-sidebar-special{
  border-radius: 2px !important;
  background-color: white;
  color: $primary !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
}

.btn-sidebar-special:hover{
  background-color: $gray-300 !important;
}

.btn , .btn-primary-outline, .btn-primary, .btn-outline-primary {
  span, i {
    color: inherit;
  }
}

.social-btn-google {
  &:hover {
    background-color: #FFF9F8 !important;
    opacity: 1 !important;
  }
}

.btn-batch {
  color: white !important;
  background: #6767E7;
  border: #6767E7;

  &:hover {
    background-color: #8957E9;
  }

  &:active {
    color: white !important;
    background-color: #8957E9 !important;
  }
}

.btn-outline-batch {
  color: #6767E7;
  background: transparent;
  border: $border-width solid #6767E7;

  &:hover {
    color: white;
    background-color: #6766E7;
  }

  &:active {
    color: white !important;
    border: $border-width solid #6767E7 !important;
    background-color: #6766E7 !important;
  }
}

.btn-api {
  color: #FFFFFF !important;
  background: #FC8829;
  border: #FC8829;

  &:hover {
    background-color: #FFB036;
  }

  &:active {
    color: white !important;
    background-color: #FFB036 !important;
  }
}

.btn-outline-api {
  color: #FC8829;
  background: transparent;
  border: $border-width solid #FC8829;

  &:hover {
    color: white;
    background-color: #FFB036;
  }

  &:active {
    color: white !important;
    border: $border-width solid #FC8829 !important;
    background-color: #FFB036 !important;
  }
}
