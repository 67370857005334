//.border-neutral-light:hover {
//  @extend .border-dark
//}
@import "variables";

//.borderThin-neutral-light {
//  border: 0.5px solid $neutral-light;
//  &:hover {
//    border: 1px solid $neutral-light;
//  }
//  &:active {
//    border: 1px solid  $dark;
//  }
//}
//
//.border-Solid {
//  border: 1px solid  $dark;
//}
//

// =====================================================
// 🎨 Mixin for Borders (Flexible & Scalable)
// =====================================================
@mixin border-style($border-color, $border-width: 0.5px) {
  border: $border-width solid $border-color;
}

// Hover & Active Mixin (Handles State Changes)
@mixin border-state($hover-color: null, $hover-width: 1px, $active-color: null, $active-width: 1px) {
  @if $hover-color {
    &:hover {
      border: $hover-width solid $hover-color;
    }
  }

  @if $active-color {
    &:active,
    &.selected { // Ensures active effect applies to selected elements
      border: $active-width solid $active-color;
    }
  }
}

// =====================================================
// 📌 Generic Border Classes
// =====================================================

// ✅ Default Border (0.5px Solid Neutral Medium)
.border-default {
  @include border-style($neutral-medium);
}

// ✅ Hover Effect: 1px Solid Neutral Light
.border-hover {
  @include border-style($neutral-medium);
  @include border-state($neutral-light);
}

// ✅ Active Effect: 1px Solid Secondary
.border-active {
  @include border-style($neutral-medium);
  @include border-state(null, null, $secondary);
}

// Add explicit selected state for persistence
.border-selected {
  border: 1px solid $secondary !important;
}

// ✅ Custom Border Class (Most Common Scenario)
// - Default: 0.5px solid Neutral Medium
// - Hover: 1px solid Neutral Light
// - Active: 1px solid Secondary
.border-custom {
  @include border-style($neutral-medium);
  @include border-state($neutral-light, 1px, $secondary, 1px);
}

// =====================================================
// 📌 Additional Utility Classes for More Control
// =====================================================

// 🔹 Solid Borders with Different Colors
.border-neutral-light {
  @include border-style($neutral-light);
}

.border-neutral-medium {
  @include border-style($neutral-medium);
}

.border-secondary {
  @include border-style($secondary);
}

// 🔹 Hover & Active Variations (Independent)
.border-hover-light {
  @include border-state($neutral-light);
}

.border-hover-medium {
  @include border-state($neutral-medium);
}

.border-hover-dark {
  @include border-state($secondary);
}

// =====================================================
// 📝 Usage in HTML
// =====================================================
// Default Border
// <div class="border-default">Default Border</div>

// Border with Hover Effect
// <div class="border-hover">Hover Border</div>

// Border with Active Effect
// <div class="border-active">Active Border</div>

// Fully Customized Border (Default, Hover & Active)
// <div class="border-custom">Custom Border</div>

// Direct Color-Specific Borders
// <div class="border-neutral-light">Light Border</div>
// <div class="border-neutral-medium">Medium Border</div>
// <div class="border-secondary">Dark Border</div>

// Independent Hover & Active Effects
// <div class="border-hover-light">Light Hover Border</div>
// <div class="border-hover-medium">Medium Hover Border</div>
// <div class="border-active-dark">Dark Active Border</div>
